.nft-container {
  max-width: 600px  !important; 
  margin: 0 auto;
  text-align: center;
}

.nft-input {
    padding: 8px;
    margin-top: 50px;
    width: 300px;

  }
 
  .image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    margin-top: 20px;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  .modal-content {
    background-color: #fff;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #fff;
    width: 300px;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .dropdown-container {
    margin-top: 10px;
  }
  
  #gmcup-dropdown {
    padding: 8px;
    width: 300px;
  }
  
  #shot-dropdown{
    padding: 8px;
    width: 300px;
  }
  
  #bottle-dropdown{
    padding: 8px;
    width: 300px;
  }
  

  .shot-overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(10%);
    transform: translateY(-35.5%);
    width: 23%;
    height: auto;
    max-width: 50%; /* Adjust the maximum width as needed */
    max-height: 50%; /* Adjust the maximum height as needed */
    z-index: 2;
    border: none;
  }
  .bottle-overlay {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(10%);
    transform: translateY(-35.5%);
    width: 23%;
    height: auto;
    max-width: 30%; 
    max-height: 30%;
    z-index: 2;
    border: none;
  }
  

  .gm-cup-v1 {
    position: absolute;
    bottom: 0;
    left: 20%;
    transform: translateX(-10%);
    transform: translateY(-15%);
    width: 60%; 
    height: 60%; 
    z-index: 2; 
  }
  .gn-cup-v1 {
    position: absolute;
    bottom: 0;
    left: 23.3%;
    transform: translateX(-10%);
    transform: translateY(-15%);
    width: 60%; 
    height: 60%; 
    z-index: 2; 
  }
  .tools-button {
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1em 2.5em;
    display: inline-block;
    border-radius: 6em;
    transition: all .2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: white;
    background-color: #000;
   }
   
   .tools-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
   }
   
   .tools-button:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
   }
   
   .tools-button::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
   }
   
   .tools-button::after {
    background-color: #000;
   }
   
   .tools-button:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
   }

   @media only screen and (max-width: 650) {
    .gm-cup-v1 {
    position: absolute;
    bottom: 0;
    left: 10%;
    transform: translateX(-10%);
    transform: translateY(-15%);
    width: 80%;
    height: 80%; 
    z-index: 2; 
    }
    .gn-cup-v1 {
      position: absolute;
      bottom: 0;
      left: 15%;
      transform: translateX(-10%);
      transform: translateY(-18%);
      width: 80%;
      height: 80%; 
      z-index: 2; 
      }
  }
  
  @media only screen and (max-width: 450px) {
    .gm-cup-v1 {
    position: absolute;
    bottom: 0;
    left: 10%;
    transform: translateX(-10%);
    transform: translateY(-18%);
    width: 80%;
    height: 80%; 
    z-index: 2; 
    }
    .gn-cup-v1 {
      position: absolute;
      bottom: 0;
      left: 15%;
      transform: translateX(-10%);
      transform: translateY(-15.5%);
      width: 80%;
      height: 80%; 
      z-index: 2; 
      }
  }
  